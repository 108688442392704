import React from "react"
import PropTypes from "prop-types"
import Link from "components/elements/link"
import "./card.scss"

const Card = ({ content, icon, redirectTo, style, subContent }) =>
  redirectTo ? (
    <Link to={redirectTo}>
      <div className="custome-card" style={style}>
        {icon && <img alt="checkmark" className="icon" src={icon} />}
        <div className="card-content">{content}</div>
        {subContent && <div className="sub-card-content">{subContent}</div>}
      </div>
    </Link>
  ) : (
    <div className="custome-card" style={style}>
      {icon && <img alt="checkmark" className="icon" src={icon} />}
      <div className="card-content">{content}</div>
      {subContent && <div className="sub-card-content">{subContent}</div>}
    </div>
  )

Card.propTypes = {
  content: PropTypes.string,
  icon: PropTypes.string,
  redirectTo: PropTypes.string,
  style: PropTypes.object,
  subContent: PropTypes.string,
}

export default Card
