import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetSiteHeader = () => {
  const query = useStaticQuery(
    graphql`
      query Header {
        contentfulGlobalHeader(name: { eq: "Header" }) {
          announcement {
            json
          }
          menu {
            menus {
              label
              links {
                path
                text
              }
            }
          }
          utilityLinks {
            path
            text
          }
          cta {
            href: path
            text
          }
        }
      }
    `
  )
  return query.contentfulGlobalHeader
}
