import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

const Link = ({
  activeClassName,
  children,
  newTab,
  partiallyActive,
  to,
  ...other
}) => {
  const internal = /^\/(?!\/)/.test(to)
  const isPdf = /.+\.pdf$/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal && !isPdf) {
    return newTab ? (
      <a {...other} href={to} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    ) : (
      <GatsbyLink
        {...other}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        to={to}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a {...other} href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  )
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  newTab: PropTypes.bool,
  partiallyActive: PropTypes.bool,
  to: PropTypes.string,
}

export default Link
