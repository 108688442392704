const Helpers = {
  capitalizeFirstLetter: string => {
    if (!string) {
      return ""
    }
    return string.charAt(0)?.toUpperCase() + string.slice(1)
  },
  getColor: color => {
    if (!color) {
      return ""
    }
    return color.charAt(0) !== "#" ? "#" + color : color
  },
  getUrlParameters: search => {
    if (!search) {
      return {}
    }
    const params = {}
    const query = search.substring(1)
    const pair = query.split("=")
    params[pair[0]] = decodeURIComponent(pair[1])

    return params
  },
  smartSlice: (array, start, count) => {
    if (!array || !Array.isArray(array) || array.length === 0 || count <= 0) {
      return []
    }
    if (count >= array.length) {
      const post = array.slice(0, start)
      return array.slice(start).concat(post)
    }

    let out = array.slice(start, start + count)
    if (out.length < count) {
      out = out.concat(array.slice(0, count - out.length))
    }
    return out
  },
}

export default Helpers
