import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetSiteFooter = () => {
  const query = useStaticQuery(
    graphql`
      query Footer {
        contentfulGlobalFooter(name: { eq: "Footer" }) {
          menu {
            menus {
              label
              links {
                path
                text
              }
            }
          }
          scheduleAppointment {
            path
            text
          }
          socialLinks {
            path
            text
          }
          utilityLinks {
            path
            text
          }
        }
      }
    `
  )
  return query.contentfulGlobalFooter
}
