import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import HELPERS from "utils/helpers"
import "./careersPageHeader.scss"

const CareersPageHeader = ({
  backgroundColor,
  classNames,
  description,
  headline,
  searchOnly,
  style,
}) => {
  const [departments, setDepartments] = useState([])
  useEffect(() => {
    fetch("https://boards-api.greenhouse.io/v1/boards/cortica/departments")
      .then(res => res.json())
      .then(result => {
        setDepartments(result.departments)
      })
  }, [])
  console.log({ departments })
  return searchOnly ? (
    <div className={classNames} id="careersPageHeader" style={style}></div>
  ) : (
    <div
      className={`text-center ${classNames}`}
      id="careersPageHeader"
      style={{
        ...style,
        backgroundColor: HELPERS.getColor(backgroundColor),
      }}
    >
      <div className="wrapper">
        {headline && <h1 className={`headline ${classNames}`}>{headline}</h1>}
        {description && (
          <div className={`subHeadline ${classNames}`}>{description}</div>
        )}
      </div>
    </div>
  )
}

CareersPageHeader.propTypes = {
  backgroundColor: PropTypes.string,
  classNames: PropTypes.string,
  description: PropTypes.string,
  headline: PropTypes.string,
  searchOnly: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  style: PropTypes.object,
}

export default CareersPageHeader
