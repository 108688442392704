import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { useStaticQueryGetDepartments } from "services/graphQl/queries/departments"
import { useStaticQueryGetSiteHeader } from "services/graphQl/queries/header"
import { useStaticQueryGetSiteFooter } from "services/graphQl/queries/footer"
import SECTIONS from "utils/sectionConstants"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Card from "components/sections/sectionList/card"
import Headline from "components/elements/headline"
import CareersPageHeader from "components/sections/careersPageHeader"
import "./departments.scss"

const Departments = ({ name }) => {
  const [departments, setDepartments] = useState([])
  const [currentDepartment, setCurrentDepartment] = useState(null)
  const departmentsTemplate = useStaticQueryGetDepartments()
  const header = useStaticQueryGetSiteHeader()
  const footer = useStaticQueryGetSiteFooter()

  useEffect(() => {
    fetch("https://boards-api.greenhouse.io/v1/boards/cortica/departments")
      .then(res => res.json())
      .then(result => {
        setDepartments(result.departments)
        setCurrentDepartment(
          result.departments.find(
            dep => `${slugify(dep.name, { lower: true })}-jobs` === name
          )
        )
      })
  }, [name])

  useEffect(() => {
    setCurrentDepartment(
      departments.find(
        dep => `${slugify(dep.name, { lower: true })}-jobs` === name
      )
    )
  }, [departments, name])

  const renderDepartmentsTemplate = (section, i) => {
    switch (section?.__typename) {
      case SECTIONS.TemplateDetailsSection:
        return (
          <div key={i}>
            <Headline headline={currentDepartment?.name} />
            <div className="career">
              <div className="container">
                <div className="row">
                  {currentDepartment?.jobs?.map((job, index) => (
                    <div className="col-md-6 col-12" key={index}>
                      <Card
                        content={job?.title}
                        index={index}
                        redirectTo={job?.absolute_url}
                        subContent={job?.location?.name}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )

      case SECTIONS.Subheader:
        return (
          <CareersPageHeader
            backgroundColor={section.backgroundColor}
            classNames="department"
            description={section.description?.description}
            headline={section.headline}
            key={i}
            searchPlaceholder={section.cta?.action?.label}
            // style={{ height: "335px" }}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <Header {...header} />
      {departmentsTemplate?.sections?.map((section, i) =>
        renderDepartmentsTemplate(section, i)
      )}
      <Footer {...footer} />
    </>
  )
}

Departments.propTypes = {
  name: PropTypes.string,
}

export default Departments
