import React from "react"
import { Router } from "@reach/router"
import PATHS from "static/constants/paths.js"
import Departments from "views/departments"

const Career = () => (
  <Router basepath={PATHS.DEPARTMENTS}>
    <Departments path="/:name" />
  </Router>
)

export default Career
