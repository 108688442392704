const PATHS = {
  BIOS: "bios",
  CARE_NOTES: "care-notes",
  DEPARTMENTS: "/careers/departments",
  LOCATIONS: "locations",
  MAKE_APPOINTMENT: "/make-an-appointment",
  STORIES: "families",
  TREATMENTS: "treatments",
  MAKE_AN_APPOINTMENT: "/welcomevisit",
}

export default PATHS
