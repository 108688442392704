import React from "react"
import PropTypes from "prop-types"
import "./headline.scss"

const Headline = ({ headline }) => <h1 className="main_headline">{headline}</h1>

export const HeadlineProps = {
  headline: PropTypes.string,
  id: PropTypes.string,
}

Headline.propTypes = HeadlineProps

export default Headline
