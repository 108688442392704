import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetDepartments = () => {
  const query = useStaticQuery(
    graphql`
      query Department {
        allContentfulWebPageTemplate(filter: { model: { eq: "Department" } }) {
          nodes {
            title
            sections {
              __typename
              ... on ContentfulTemplateSection {
                __typename
                title
              }
              ... on ContentfulSubheader {
                __typename
                backgroundColor
                headline
                cta {
                  name
                  action {
                    __typename
                    ... on ContentfulActionForm {
                      __typename
                      formType
                      label
                      url
                    }
                  }
                }
                description {
                  description
                }
              }
            }
          }
        }
      }
    `
  )
  return query.allContentfulWebPageTemplate.nodes[0]
}
